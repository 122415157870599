import React from 'react';
import { Icon } from 'antd';

const LoadingCenterPlain = () => (
    <div style={{
      position: 'absolute',
      left: '50%',
      marginLeft: '-30px',
      marginTop: '100px',
      color: '#AAA'
    }}>
        <div style={{
          margin: '10px',
          fontSize: '3em'
        }}>
            <Icon type="loading" />
        </div>
        <div style={{
          margin: '-15px 6px 10px 6px',
          fontSize: '1.2em'
        }}>
            Loading...
        </div>
    </div>
);

export default LoadingCenterPlain;
